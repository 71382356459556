export const Solid = () => (
  <svg
    width="170"
    height="170"
    viewBox="450 500 1000 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1140.85 366.132C1107.05 373.301 1074.91 390.006 1048.67 416.247C999.682 465.237 920.253 465.237 871.263 416.247L871.234 416.276C859.621 404.663 840.81 404.663 829.197 416.276C817.584 427.889 817.584 446.7 829.197 458.313C878.187 507.303 878.187 586.732 829.197 635.722C780.207 684.712 700.792 684.698 651.802 635.708C602.812 586.718 602.812 507.318 651.802 458.327L871.277 238.852C920.253 189.876 999.682 189.876 1048.66 238.852C1060.27 250.465 1079.1 250.479 1090.71 238.867C1102.32 227.254 1102.31 208.428 1090.69 196.815L1090.74 196.772C1018.52 124.556 901.413 124.556 829.197 196.772L609.722 416.247C537.506 488.463 537.506 605.572 609.722 677.788C655.711 723.778 719.906 740.483 779.096 727.889C812.881 720.734 845.036 704.014 871.263 677.788C920.253 628.798 999.682 628.798 1048.67 677.788C1060.28 689.401 1079.1 689.401 1090.71 677.788C1102.32 666.175 1102.32 647.364 1090.71 635.751L1090.74 635.722C1041.75 586.732 1041.75 507.303 1090.74 458.313C1139.73 409.323 1219.14 409.337 1268.13 458.327C1317.12 507.318 1317.11 586.732 1268.13 635.708L1048.66 855.183C999.667 904.173 920.253 904.159 871.277 855.183L871.248 855.212C859.635 843.599 840.81 843.613 829.197 855.226C817.584 866.839 817.584 885.65 829.197 897.263C901.413 969.479 1018.52 969.479 1090.74 897.263L1310.21 677.788C1382.43 605.572 1382.43 488.463 1310.21 416.247C1264.22 370.257 1200.06 353.552 1140.85 366.132ZM998.556 585.607C973.138 580.211 946.796 580.211 921.378 585.607C926.773 560.188 926.773 533.847 921.378 508.428C946.796 513.824 973.138 513.824 998.556 508.428C993.161 533.847 993.161 560.188 998.556 585.607Z"
      fill="url(#paint0_linear_36_59)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_36_59"
        x1="719.863"
        y1="787.93"
        x2="1200.24"
        y2="307.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6086FF" />
        <stop offset="1" stop-color="#FF47C2" />
      </linearGradient>
    </defs>
  </svg>
);
