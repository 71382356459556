import styles from "./App.module.css";
import { Solid } from "./Solid";

export default function App() {
  return (
    <>
      <div className={styles.header}>
        <a href="https://app.solid.online">
          <button className={styles.button}>LAUNCH WEB APP</button>
        </a>
      </div>
      <div className={styles.container}>
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <div className={styles.title}>
            <div style={{ display: "flex", alignItems: "center" }}>
              Solid
              <Solid />
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "50px" }}
          >
            <div className={styles.subTitle}>
              Re-writing the future <br></br>
              of De-Fi on Terra
            </div>
            <a href="/whitepaper-v2.pdf" target="_blank">
              <button className={styles.button}>LEARN MORE</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
